<template>
  <div class="box">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="currentChangeHandle(1)" @submit.native.prevent>
      <table class="detailTable_search" style="width: 100%">
        <tr>
          <td class="titleTd width80px">指标名称</td>
          <td class="valueTd">
            <el-input v-model="dataForm.orkName" placeholder="请输入指标名称" clearable></el-input>
          </td>
        </tr>
      </table>
      <div class="wxts_msg_search">
        <div class="f_t">
          <el-button-group :disabled="dataListLoading">
            <el-button size="mini" @click="currentChangeHandle(1)" type="success" :loading="dataListLoading">查询</el-button>
            <el-button size="mini" v-if="isAuth('dataflow:olxareaauthmain:save') && getCode() == 1000" type="primary"
              @click="addOrUpdateHandle()">新增
            </el-button>
            <!-- <el-button v-if="isAuth('dataflow:olxareaauthmain:delete')" type="danger" @click="deleteHandle()"
                                     :disabled="dataListSelections.length <= 0">批量删除
                          </el-button> -->
          </el-button-group>
        </div>
      </div>
    </el-form>
    <el-table :data="dataList" border v-loading="dataListLoading" @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column prop="orkName" header-align="center" align="center" :show-overflow-tooltip="true" label="指标名称">
      </el-table-column>
      <el-table-column prop="orkState" header-align="center" align="center" label="状态">
        <template slot-scope="scope">
          <sysDict type="management_index_state" :value="scope.row.orkState"></sysDict>
        </template>
      </el-table-column>
      <el-table-column prop="orkYear" header-align="center" align="center" label="指标年份">
      </el-table-column>
      <el-table-column prop="userName" header-align="center" align="center" label="填写人">
      </el-table-column>
      <el-table-column prop="areaCode" header-align="center" align="center" label="所属区域" v-if="getCode() != 1000">
        <template slot-scope="scope">
          <org :value="scope.row.areaCode" />
        </template>
      </el-table-column>
      <el-table-column prop="fillDate" header-align="center" align="center" label="填写时间">
      </el-table-column>
      <el-table-column fixed="right" header-align="center" align="center" width="150" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small"
            v-if="isAuth('dataflow:olxpokrmanagemain:update') && scope.row.orkState == '01' && getCode() == 1000"
            @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" v-if="getCode() == 1000 && scope.row.issueFiliale != '1'"
            @click="transmitHandle(scope.row.id)">下发</el-button>
          <el-button type="text" size="small"
            v-if="isAuth('dataflow:olxpokrmanagemain:update') && scope.row.orkState == '02' && scope.row.areaCode == regionCode"
            @click="accept(scope.row.id)">接收</el-button>
          <el-button type="text" size="small" v-if="scope.row.decomposedMonth != '1' && scope.row.areaCode == regionCode"
            @click="disassemble(scope.row.id, scope.row.orkYear, scope.row.areaCode)">分解</el-button>
          <el-button type="text" size="small"
            v-if="isAuth('dataflow:olxpokrmanagemain:update') && scope.row.decomposedMonth == '1' && scope.row.areaCode == regionCode" 
            @click="issuedHandle(scope.row.id,type,parentType)">{{(type===5||parentType===5) ?'分解信息' : '分解信息（下达)'}}</el-button>
          <el-button type="text" size="small" v-if="isAuth('dataflow:olxpokrmanagemain:update') && getCode() == 1000"
            @click="viewHandle(scope.row.id)">查看</el-button>
          <el-button type="text" size="small" class="del-button"
            v-if="isAuth('dataflow:olxpokrmanagemain:delete') && scope.row.orkState == '01' && getCode() == 1000"
            @click="deleteHandle(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <disassemble v-if="addOrUpdateVisible" ref="disassemble" @refreshDataList="getDataList" @closeX="closeX">
    </disassemble>
    <issude v-if="addOrUpdateVisible" ref="issude" @refreshDataList="getDataList"></issude>
    <transmit v-if="addOrUpdateVisible" ref="transmit" @refreshDataList="getDataList"></transmit>
    <examine v-if="addOrUpdateVisible" ref="examine" @refreshDataList="getDataList"></examine>
</div>
</template>

<script>

import AddOrUpdate from './add-or-update'
import disassemble from './disassemble'
import issude from './issued/index'
import transmit from './transmit'
import examine from './examine'
import { getList, deleteData, getUpdateOrkState } from '@/api/dataflow/olxpokrmanagemain.js'
export default {
  data() {
    return {
      dataForm: {
        orkName: ''
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false
    }
  },
  components: {
    AddOrUpdate, disassemble, issude, transmit, examine
  },
  activated() {
    //this.dataForm.key =  this.$route.query.key;

    let pageIndex = this.$route.query.pageIndex;
    if (pageIndex) {
      this.pageIndex = parseInt(pageIndex);
    }

    let pageSize = this.$route.query.pageSize;
    if (pageSize) {
      this.pageSize = parseInt(pageSize);
    }

    this.getDataList();
  },
  computed: {
    regionCode: {
      get() {
        console.log("user=>"+JSON.stringify(this.$store.state.user))
        const newregionId = this.$store.state.user.regionCode;
        if (newregionId !== undefined) {
          return newregionId;
        } else {
          return ''
        }
      }
    },
    type : {
      get() {
        const newType = this.$store.state.user.type;
        console.log("Type=>" + newType)
        if (newType !== undefined) {
          return newType;
        } else {
          return ''
        }
      }
    },
    parentType: {
      get() {
        const newParentType = this.$store.state.user.parentType;
        // console.log("parentType=>" + newParentType)
        if (newParentType !== undefined) {
          return newParentType;
        } else {
          return ''
        }
      }
    }
  },
  methods: {
    refresh() {
      // this.$router.push({name:'dataflow_olxpokrmanagemain',query: {key:this.dataForm.key,pageIndex:this.pageIndex,pageSize:this.pageSize}});
    },
    getCode() {
      const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
      return code
    },
    // 获取数据列表
    getDataList() {
      // console.log("父页面getDataList")
      const code = this.$store.state.user.regionId !== undefined ? this.$store.state.user.regionId : '0'
      this.dataListLoading = true
      const params_ = {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'orkName': this.dataForm.orkName,
        'areaCode': this.regionCode
      }
      getList(params_).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list
          this.totalCount = data.data.totalCount
        } else {
          this.dataList = []
          this.totalCount = 0
          this.$message.error(data.msg)
        }
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id)
      })
    },
    //下发
    transmitHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.transmit.init(id)
      })
    },
    //接受
    accept(id) {
      this.$confirm(`确定要接受该任务`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        getUpdateOrkState(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '接受成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    },
    //分解
    disassemble(id, orkYear, areaCode) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.disassemble.init(id, orkYear, areaCode)
      })
    },

    closeX() {
      this.addOrUpdateVisible = false
    },

    //查看
    viewHandle(id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.examine.init(id)
      })
    },
    //分解信息（下达）
    issuedHandle(id,type,parentType) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.issude.init(id,type,parentType)
      })
    },
    // 删除
    deleteHandle(row) {
      const ids = row ? [row.id] : this.dataListSelections.map(item => {
        return item.id
      })
      //const names = row ? [row.name] : this.dataListSelections.map(item => {
      //  return item.name
      //})
      this.$confirm(`确定要进行[${row ? '删除' : '批量删除'}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataListLoading = true
        deleteData(ids).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000
            })
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
        })
      }).catch(_ => { })
    }
  }
}
</script>
